<template>
  <div class="contenido" v-if="user !== null">
    <b-modal
      v-model="isModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span>
                <font-awesome-icon
                  class="icono-modal white"
                  :icon="['fal', 'lightbulb-on']"/>
                <span class="title-m bold white">{{
                  $t("COMPETITION.STIMULUS_LOWER")
                }}</span></span>
            </div>
            <div>
              <button
                class="btn-modal btn button"
                @click="isModalActive = false">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("THANKS_YOU.CLOSE")
                  }}</span></span>
              </button>
            </div>
          </div>
          <div class="stimulus text-l gray">
            <div class="instructions">
              <h4 class="subtitle">
                {{ $t("COMPETITION.STIMULUS_INS.INCOMING") }}
              </h4>
              <p style="font-weight: bold;" v-html="$t('COMPETITION.STIMULUS_INS.TITLE')"></p>
              <p v-html="$t('COMPETITION.STIMULUS_INS.P1')"></p>
              <p v-html="$t('COMPETITION.STIMULUS_INS.P2')"></p>
              <br />
            </div>
            <hr v-if="!isNotInit" />
            <div
              class="estimulo"
              v-if="!isNotInit && estimuloCreativo !== null">
              <p>
                <img :src="'data:image;base64,'+estimuloCreativo.file_data" :title="estimuloCreativo.orig_filename" alt=""/>
              </p>
            </div>
            <div class="columns" v-if="!isNotInit && estimuloCreativo !== null && user?.necesidades_especiales?.includes('INVIDENTE')">
              <div class="column has-text-centered player-container">
                <audio style="width: 300px; height: 50px;" controls>
                  <source src="../assets/relatos_audios/es.mp3" type="audio/mpeg">
                </audio>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="isModalInsActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span
                ><font-awesome-icon
                  class="icono-modal white"
                  :icon="['fal', 'file-alt']"
                />
                <span class="title-m bold white">{{
                  $t("COMPETITION.DECALOGO.TITLE")
                }}</span></span
              >
            </div>
            <div>
              <button
                class="btn-modal btn button"
                @click="isModalInsActive = false"
              >
                <span
                  ><font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("THANKS_YOU.CLOSE")
                  }}</span></span
                >
              </button>
            </div>
          </div>
          <div class="stimulus text-l gray">
            <p v-html="$t('COMPETITION.DECALOGO.P1')"></p>
            <br />
            <p v-html="$t('COMPETITION.DECALOGO.P2')"></p>
            <br />
            <p v-html="$t('COMPETITION.DECALOGO.P3')"></p>
            <br />
            <p v-html="$t('COMPETITION.DECALOGO.P4')"></p>
            <br />
            <p v-html="$t('COMPETITION.DECALOGO.P5')"></p>
            <br />
            <p v-html="$t('COMPETITION.DECALOGO.P6')"></p>
            <br />
            <p v-html="$t('COMPETITION.DECALOGO.P7')"></p>
            <br />
            <p v-html="$t('COMPETITION.DECALOGO.P8')"></p>
            <br />
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="isModalFaqActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span>
                <font-awesome-icon class="icono-modal white" :icon="['fal', 'question-circle']" />
                <span class="title-m bold white">{{
                  $t("COMPETITION.FAQs.TITLE")
                  }}</span>
              </span>
            </div>
            <div>
              <button class="btn-modal btn button" @click="isModalFaqActive = false">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("THANKS_YOU.CLOSE")
                    }}</span>
                </span>
              </button>
            </div>
          </div>
          <div class="stimulus text-l gray">
            <p v-html="$t('COMPETITION.FAQs.P1')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P2')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P3')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P4')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P5')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P6')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P7')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P8')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P9')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P10')"></p>
            <br />
            <p v-html="$t('COMPETITION.FAQs.P11')"></p>
            <br />
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="showWelcomeMessage"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior welcome">
            <div>
              <button
                class="btn-modal btn button"
                @click="showWelcomeMessage = false">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("THANKS_YOU.CLOSE")
                  }}</span></span>
              </button>
            </div>
          </div>
          <div class="stimulus text-l-gray">
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center">
                <img src="../assets/icons/bienvenida.svg" />
              </div>
            </div>
            <div class="columns is-centered">
              <div
                class="column is-10 is-flex is-justify-content-center has-text-centered">
                <h2 class="welcome-message-title">
                  {{ $t("COMPETITION.WELCOME_MESSAGE.TITLE") }}
                </h2>
              </div>
            </div>
<!--             <div class="columns is-centered">
              <div
                class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.WELCOME_MESSAGE.P1')"></p>
              </div>
            </div> -->
            <div class="columns is-centered">
              <div
                class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph">
                  {{ $t("COMPETITION.WELCOME_MESSAGE.P2") }}
                </p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <h4 class="title-m">
                  {{ $t("COMPETITION.DECALOGO.TITLE") }}
                </h4>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P1')"></p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P2')"></p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P3')"></p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P4')"></p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P5')"></p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P6')"></p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P7')"></p>
              </div>
            </div>
            <div class="columns is-centered">
              <div class="column is-10 is-flex is-justify-content-center has-text-centered">
                <p class="welcome-message-paragraph" v-html="$t('COMPETITION.DECALOGO.P8')"></p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <ImageHeader :img="'Fondo-cabecera-nino'" />
    <div class="is-10 container-data container">
      <div
        class="columns"
        v-if="isNotInit && !relato.isFinalizado"
        :class="{ 'is-not-init': isNotInit && !relato.isFinalizado }">
        <div
          class="is-12 column has-text-centered remaining-message"
          v-if="days == 0">
          <p class="text-l white bold text-time">
            {{ $t("COMPETITION.TIME") }}
          </p>
        </div>
      </div>
      <div
        class="columns"
        v-if="!relato.isFinalizado"
        :class="{ 'is-finalizado': !isNotInit && !relato.isFinalizado }">
        <div
          class="timer is-2 column is-offset-5"
          :class="{ 'not-init': isNotInit }">
          <p class="title-l white bold">
            <span v-if="days > 0">{{ days | formatTime }}d</span>
            {{ hours | formatTime }}:{{ minutes | formatTime }}:{{
              seconds | formatTime
            }}
          </p>
        </div>
      </div>
      <div
        class="container is-10 task"
        :class="{ 'is-finalizado': relato.isFinalizado }">
        <div class="columns info">
          <div class="is-4 is-offset-1 column has-text-left">
            <p class="title-m black bold">
              {{ user.nombre }} {{ user.primer_apellido }}
              {{ user.segundo_apellido }}
            </p>
            <br />
            <p class="text-m black">
              <font-awesome-icon :icon="['fal', 'globe']" />
              {{ user.idioma_nombre }}
            </p>
          </div>
          <div class="is-6 column has-text-right">
            <div class="buttons-norm">
              <button class="btn button" @click="isModalFaqActive = true">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'question-circle']" />
                  <span class="text-m black">{{
                    $t("COMPETITION.FAQ")
                    }}</span>
                </span>
              </button>
              <button class="btn button" @click="isModalInsActive = true">
                <span><font-awesome-icon class="icono" :icon="['fal', 'file-alt']"/>
                  <span class="text-m black">{{
                    $t("COMPETITION.INSTRUCTIONS")
                  }}</span>
                </span>
              </button>
              <button class="btn button" @click="openEstimulo()">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'lightbulb-on']" />
                  <span class="text-m black">{{
                    $t("COMPETITION.STIMULUS")
                  }}</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <hr class="division" />
        <form>
          <div class="columns">
            <div class="is-10 column is-offset-1 has-text-left">
              <b-field :label="$t('COMPETITION.TITLE')">
                <b-input
                  custom-class="custom-input-background-gray"
                  name="title"
                  v-model.trim="relato.title"
                  :readonly="isNotInit || relato.isFinalizado"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="is-10 column is-offset-1 has-text-left">
              <b-field :label="$t('COMPETITION.TEXT')">
                <b-input
                  custom-class="text-area custom-input-background-gray"
                  type="textarea"
                  v-validate="'required'"
                  name="body"
                  v-model.trim="relato.body"
                  :readonly="isNotInit || relato.isFinalizado"
                  @copy.native.prevent
                  @paste.native.prevent
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="is-5 is-offset-1 column">
              <p class="text-m black">
                <font-awesome-icon :icon="['fal', 'headset']" />
                {{ $t("COMPETITION.PROBLEMS") }}<a href='https://www.fundacioncocacola.com/concurso-jovenes-talentos/contacto'
                  target='_blank'>{{ $t("COMPETITION.PEOPLE") }}</a>
              </p>
            </div>
            <div class="is-5 column  has-text-right">
              <span class="">{{ charactersLeft }}</span>
            </div>
          </div>
          <div class="columns buttons-footer" v-if="!relato.isFinalizado">
            <div class="is-6 is-offset-1 column has-text-left">
              <button
                class="btn button"
                :disabled="isNotInit"
                @click="validateBeforeSubmit(false)"
                type="button"
              >
                <span
                  ><font-awesome-icon class="icono" :icon="['fal', 'save']" />
                  <span class="text-m black save-text">{{
                    $t("COMPETITION.SAVE")
                  }}</span></span
                >
              </button>

              <span
                class="text-m auto-save"
                v-if="!isNotInit && timeSaved !== ''"
                >
                <span style="position: relative; top: -5px;">
                  {{ $t("COMPETITION.AUTO_SAVE", { time: timeSaved }) }} 
                </span>
                <font-awesome-icon v-if="!showLoadingSaved" style="margin-left: 6px" class="icon" :icon="['fal', 'check']" />
                <font-awesome-icon v-if="showLoadingSaved" style="margin-left: 6px" class="icon animated" :icon="['fal', 'sync']" />
              </span
              >
            </div>
            <div class="is-3 is-offset-1 column has-text-right">
              <button
                class="btn-finish button"
                :disabled="isNotInit || relato.isFinalizado"
                @click="validateBeforeSubmit(true)"
                type="button"
              >
                <span class="text-l bold white">{{
                  $t("COMPETITION.FINISH")
                }}</span>
              </button>
            </div>
          </div>
          <div class="columns buttons-footer pb-4" v-if="relato.isFinalizado && this.relato.body">
            <div class="is-12 has-text-centered column" v-if="currentUser">
              <p class="title-m black bold">{{$t("COMPETITION." + currentUser.warning)}}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";

export default {
  name: "competition",
  components: {
    ImageHeader,
  },
  data() {
    return {
      currentTime: null,
      speed: 1000,
      deadline: "",
      user: null,
      currentUser: null,
      relato: {
        title: "",
        body: null,
        isFinalizado: false,
      },
      isNotInit: true,
      isModalActive: false,
      isModalInsActive: false,
      isModalFaqActive: false,
      fechaInicio: "2021-01-21 15:43",
      fechaFin: "2021-01-21 17:43",
      now: "",
      timeSaved: "",
      intervalAutoguardado: 1,
      showWelcomeMessage: false,
      estimuloCreativo: null,
      timeoutInterval: null,
      timeoutTime: null,
      showLoadingSaved: false
    };
  },
  computed: {
    charactersLeft() {
      var wordsTrim =
        this.relato.body === null ? [] : this.relato.body.split(" ");
      wordsTrim = wordsTrim.filter((w) => w !== "");

      var words = wordsTrim.length,
        limit = 800;

      if (words > limit) {
        var wordLimited = this.relato.body.split(" ");
        wordLimited.splice(limit, words);
        this.maxWords(wordLimited.join(" "));
        words--;
      }
      return words + " / " + limit;
    },
    seconds() {
      return Math.floor((this.currentTime / 1000) % 60);
    },
    minutes() {
      return Math.floor((this.currentTime / 1000 / 60) % 60);
    },
    hours() {
      return Math.floor((this.currentTime / (1000 * 60 * 60)) % 24);
    },
    days() {
      return Math.floor(this.currentTime / (1000 * 60 * 60 * 24));
    },
  },
  filters: {
    formatTime(value) {
      if (value < 10) {
        return "0" + value;
      }
      return value;
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.updateStory(true);
  },
  destroyed() {
    clearTimeout(this.timeoutInterval);
    clearTimeout(this.timeoutTime);
    document.removeEventListener("visibilitychange", this.checkStateWindow);
  },
  methods: {
    checkStateWindow() {
      if(this.timeoutInterval) {
        clearTimeout(this.timeoutInterval);
      }
      if(this.timeoutTime) {
        clearTimeout(this.timeoutTime);
      }

      if (document.visibilityState === "visible") {
        this.updateStory(false);
      }
    },
    updateStory(isFirstTime) {
      if(isFirstTime) {
        this.$store.commit("setShowSpinner", true);
      }

      this.$store
      .dispatch("getRelato", { idioma: this.$i18n.locale })
      .then((relato) => {
        if(isFirstTime) {
          this.relato.isFinalizado = relato.finalizado;
          if(!this.relato.isFinalizado) {
            document.addEventListener("visibilitychange", this.checkStateWindow);
          }
          if (relato.redaccion) {
            this.relato.title = relato.titulo;
            this.relato.body = relato.redaccion;
  
            if (relato.date_updated) {
              let date = new Date(relato.date_updated);
              this.timeSaved = `${this.formatTime(date.getHours())}:${this.formatTime(date.getMinutes())}`;
            }
          }
        }
        this.$store
          .dispatch("getUserIdioma")
          .then((user) => {
            this.user = user;
            this.fechaInicio = new Date(user.fecha_inicio);
            this.fechaFin = new Date(user.fecha_fin);
            this.now = new Date(user.now);
            if (this.fechaInicio < this.now) {
              if (this.fechaFin > this.now) {
                this.isNotInit = false;
                this.deadline = this.fechaFin;
              } else {
                this.relato.isFinalizado = true;
                this.isNotInit = true;
              }
            } else {
              this.deadline = this.fechaInicio;
              const today = new Date(user.now);
              today.setHours(0);
              if (this.fechaInicio > today && isFirstTime) {
                this.showWelcomeMessage = true;
              }
            }
            if (!this.relato.isFinalizado) {
              this.timeoutTime = setTimeout(this.countdown, 0);
            }
            if (!this.relato.isFinalizado && !this.isNotInit) {
              this.timeoutInterval = setTimeout(
                this.autoGuardado,
                this.intervalAutoguardado * 60 * 1000
              );
            }
            if(isFirstTime) {
              this.$store.commit("setShowSpinner", false);
            } else {
              if (this.fechaFin < this.now) {
                document.removeEventListener("visibilitychange", this.checkStateWindow);
                this.validateBeforeSubmit(false);
              }
            }
          })
          .catch((error) => {
            if(isFirstTime) {
              this.$store.commit("setShowSpinner", false);
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
    },
    openEstimulo() {
      if (!this.isNotInit) {
        if (this.$store.state.relatos.estimuloCreativo === null) {
          this.$store
            .dispatch("getEstimuloCreativo", {
              prueba_id: this.user.prueba_id,
            })
            .then((response) => {
              this.$store.commit("setEstimuloCreativo", response);
              this.estimuloCreativo = response;
              this.isModalActive = true;
            })
            .catch((error) => {
              this.$store.commit("setShowSpinner", false);
            });
        } else {
          this.isModalActive = true;
        }
      } else {
        this.isModalActive = true;
      }
    },
    maxWords(wordLimited) {
      this.relato.body = new String(wordLimited);
    },
    countdown() {
      const currentDate = this.now;
      currentDate.setSeconds(currentDate.getSeconds() + 1);
      this.currentTime = Date.parse(this.deadline) - Date.parse(currentDate);
      if (this.currentTime > 0) {
        this.timeoutTime = setTimeout(this.countdown, this.speed);
      } else {
        if (this.isNotInit) {
          this.isNotInit = false;
          this.deadline = this.fechaFin;
          this.timeoutTime = setTimeout(this.countdown, 0);
          this.timeoutInterval = setTimeout(
            this.autoGuardado,
            this.intervalAutoguardado * 60 * 1000
          );
        } else {
          this.validateBeforeSubmit(true);
          this.currentTime = null;
          clearTimeout(this.timeoutInterval);
          clearTimeout(this.timeoutTime);
        }
      }
    },
    autoGuardado() {
      this.validateBeforeSubmit(false);
      this.timeoutInterval = setTimeout(
        this.autoGuardado,
        this.intervalAutoguardado * 60 * 1000
      );
    },
    validateBeforeSubmit(finalizado) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const relato = {
            titulo: this.relato.title,
            redaccion: this.relato.body,
            idioma: this.$i18n.locale,
            finalizado,
          };
          if (finalizado && this.currentTime > 0) {
            this.$buefy.dialog.confirm({
              title: this.$t("COMPETITION.FINISH_TITLE"),
              message: this.$t("COMPETITION.FINISH_MESSAGE"),
              confirmText: this.$t("COMPETITION.FINISH_LOWER"),
              cancelText: this.$t("COMPETITION.CANCELAR"),
              type: "is-danger",
              hasIcon: true,
              iconPack: "far",
              onConfirm: () => {
                this.guardarRelato(relato, finalizado);
              },
            });
          } else {
            this.guardarRelato(relato, finalizado);
          }
        }
      });
    },
    guardarRelato(relato, finalizado) {
      this.$store
        .dispatch("createRelatos", {
          ...relato,
          prueba: this.user.prueba_id,
        })
        .then((response) => {
          if (response.finalizado) {
            if(this.currentTime > 0) {
              this.$router.push("gracias-por-participar-certificado");
            } else {
              this.$buefy.dialog.alert({
              title: this.$t("COMPETITION.TIME_FINISH_TITLE"),
              type: "is-danger",
              message: this.$t("COMPETITION.TIME_FINISH"),
              confirmText: this.$t("COMPETITION.CONTINUE"),
              onConfirm: () => {
                this.$router.push("gracias-por-participar-certificado");
              },
            });
            }
          } else {
            let now = new Date();
            this.relato.date_updated = now;
            this.timeSaved = `${this.formatTime(
              now.getHours()
            )}:${this.formatTime(now.getMinutes())}`;

            this.showLoadingSaved = true;

            setTimeout(() => {
              this.showLoadingSaved = false;
            }, 3000);
          }
        })
        .catch((error) => {
        });
    },
    formatTime(value) {
      if (value < 10) {
        return "0" + value;
      }
      return value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

a {
  color: $primary;
}

.columns.buttons-footer {
  margin-top: 2em;
}

.text-time {
  font-size: 22px;
  margin-bottom: 10px;
}

.is-not-init {
  margin-top: 55px !important;
}

.is-finalizado {
  margin-top: 80px !important;
}

.limiter {
  padding-top: 0;
}

.container-data {
  margin-top: -230px;
  padding-bottom: 120px;
}

.task {
  background-color: white;
  margin-top: -40px;
}

.timer {
  text-align: center;
  z-index: 10;
  border-radius: 30px;
  background-color: $primary;
  padding: 20px;
  // margin-top: 75px;
}

.timer.not-init {
  background-color: #707070;
}

.info {
  padding-top: 30px;
}

.btn {
  background-color: white;
  height: 50px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  // width: 100%;
  .content-button {
    display: flex;
    align-items: center;
  }

  .icono {
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
  }
}

.stimulus:hover {
  color: black;
}

.division {
  width: 100%;
  margin-bottom: 20px;
}

.btn-finish {
  background-color: $primary;
  color: white;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 30px;
  width: 100%;
}

.btn-finish:hover {
  color: white;
}

.save-text {
  margin-left: 25px;
  margin-right: 25px;
}

.icono-modal {
  font-size: 25px;
  margin-right: 20px;
}

.btn-modal {
  background-color: $primary;

  .icono {
    color: white;
  }
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parte-superior.welcome {
  justify-content: flex-end;
}

.stimulus {
  padding: 40px;
  background-color: white;
  margin-top: 25px;
  border-radius: 10px;
}

.auto-save {
  font-style: italic;
  margin-left: 25px;
  position: relative;
  top: 18px;
}

.instructions {
  h4 {
    font-weight: bold;
  }
}
.instructions .subtitle {
  font-size: 20px;
}

.welcome-message-title {
  font-size: 35px;
  font-weight: bold;
  color: black;
  line-height: normal;
}

.welcome-message-paragraph {
  font-size: 16px;
}

.buttons-norm .btn.button {
  margin-left: 12px;
}

.animated {
  animation: rotate 2s linear infinite;
  color: $primary;
}

.player-container {
  justify-content: center;
  display: flex;
  padding-top: 40px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
